

body {
    background-color: #007a7e;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(2%, #060042), color-stop(108%, #20A39E));
    background-image: linear-gradient(#007a7e, #007dc5 20%, #3f0069 108%);
    color: #ffffff;
    min-height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    text-align: center;
}

.logo {
    clip-path: circle();
    width: 120px;
    margin-top: 30px;
    text-decoration: none;
}

.logoLink {
    color: transparent;
}

.standardLink, .featured {

    display: block;
    max-width: 300px;
    color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 18px;
    margin: 0 auto 15px auto;
    padding: 15px 20px;
    text-decoration: none;
    transition: all 200ms ease-in-out;

    &.featured {
        position: relative;
        background-color: #ffffff;
        color: #3f0069;
        font-weight: 300;
        font-size: 18px;

        // &:before {
        //     content: "";
        //     position: absolute;
        //     top: 2px;
        //     left: 2px;
        //     width: calc(99% - 4px);
        //     height: calc(99% - 4px);
        //     border: 2px solid #060042;
        // }
        @media (hover: hover) {
            &:hover {
                background-color: transparent;
                color: #ffffff;
            }
        }
    }

    @media (hover: hover) {
        &:hover {
            background-color: #ffffff;
            color: #3f0069
        }
    }
}

p {
    
    margin: auto;
    margin-top: 15px;
    max-width: 750px;
    text-align: left;
    font-size: 18px;
    
}

a {
    color: white;
    text-decoration: underline;
}

.blogTitle {
    color: white;
    font-size: 30px;
    font-weight: 350px;
    text-decoration: underline;
}

.verticalHeader, .favoritesTitle, .favoritesHeader {
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 2px;
    margin-top: 45px;

    &.favoritesTitle {
        font-size: 36px;
        font-weight: 900;
        letter-spacing: 3px;
    }
    &.favoritesHeader {
        text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.349);
        
    }
}

.channels {
    display: flex;
    max-width: 400px;
    margin: auto;
    a {
        flex: 1;
        padding: 8px 10px;
        max-width: 150px;
        text-align: center;
        
        
    }
}

// h3, h4 {
    
// }

.giphy-embed {
    pointer-events: none;
}

.blogPostContentDiv {
    margin: auto; 
    margin-top: 30px;
    padding: 30px;
    max-width: 750px; 
    text-align: left; 
    background-color: rgba(255, 255, 255, 0.5);
    color: black;
    a {
        color: rgb(0, 92, 168);
    }
    @media (prefers-color-scheme: dark) {
        background-color: rgba(0,0,0, 0.5);
        color: white;
        a {
            color: rgb(255, 217, 0);
        }
    }
    
    border-radius: 25px;
}

.favoritesDescription, .blogDescription {
    font-weight: 300;
    margin: auto;
    max-width: 500px;
    text-align: left;
    margin-bottom: 30px;

    &.favoritesDescription {
        max-width: 450px;
    }
}

#insta, #twitter, #twitch {
    @media (hover: hover) {
        &:hover {
            color: #ffffff
        }
    }
}
#insta {
    @media (hover: hover) {
        &:hover {
            background-color: #69305b;
        }
    }
}
#twitter {
    @media (hover: hover) {
        &:hover {
            background-color: rgb(0, 73, 95);
        }
    }
}
#twitch {
    @media (hover: hover) {
        &:hover {
            background-color: rgb(73, 18, 73);
        }
    }
}


#blogFrame {
    margin-top: 30px;
    
    background-color: white;
    color: black;
    border-radius: 5px;
    border: none;
}